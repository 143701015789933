.sectionYourFurniActivity {
    position: relative;
    width: 600px;
    max-width: 100%;
    margin: 25px auto 0;

    &__titles {
        display: grid;
        grid-template-areas:
            "subTitle subTitle money"
            "title title money"
    }

    &__title,
    &__subTitle {
        font-family: 'FuturaPT';
        color: #F0F0F0;
    }

    &__title {
        grid-area: title;
        font-weight: 700;
        font-size: 46px;
        line-height: 49px;
        margin-right: 10px;

        @media (max-width: 380px) {
            font-size: 40px;
            line-height: 43px;
        }

        @media (max-width: 350px) {
            font-size: 35px;
            line-height: 38px;
        }
    }

    &__subTitle {
        grid-area: subTitle;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    &__imgMoney {
        display: grid;
        justify-self: end;
        grid-area: money;
    }

    &__content {
        position: relative;
        margin-top: 30px;
        height: 580px;
    }

    &__codeCopied {
        position: absolute;
        z-index: 20;
        left: calc(50% - 51px);
        bottom: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 31px;
        background: #EFF2FD;
        border-radius: 12px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #0E0F12;
    }

}