.listDescriptionWorksFurni {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media (max-width: 920px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 480px) {
        gap: 24px;
    }

    &__item {
        padding: 48px 40px;
        background-color: #262830;
        border: 2px solid #323540;
        border-radius: 24px;

        @media (max-width: 480px) {
            padding: 32px 16px;
        }
    }

    &__title {
        margin-top: 17px;
        font-family: 'FuturaPT';
        font-weight: 700;
        font-size: 40px;
        line-height: 43px;
        color: #F0F0F0;

        @media (max-width: 480px) {
            font-size: 32px;
            line-height: 36px;
        }

        @media (max-width: 365px) {
            font-size: 25px;
            line-height: 29px;
        }
    }

    &__title.howItworks {
        font-family: 'FuturaPT';
        font-weight: 700;
        font-size: 32px;
        line-height: 35px;
        color: #F0F0F0;

        @media (max-width: 480px) {
            font-size: 20px;
            line-height: 23px;
        }
    }

    &__subTitle {
        margin-top: 24px;
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #E0E0E0;

        @media (max-width: 480px) {
            margin-top: 24px;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.listFurniServices {
    display: grid;
    margin-top: 23px;
    gap: 27px;

    @media (max-width: 480px) {
        gap: 22px;
        margin-top: 18px;
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: 17px;
        width: 30px;
        height: 30px;

        @media (max-width: 480px) {
            width: 22px;
            height: 22px;
        }
    }

    &__text {
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #E0E0E0;

        @media (max-width: 480px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.sectionMainPageNavigation {
    display: flex;
    margin-top: 36px;
    gap: 20px;

    @media (max-width: 480px) {
        margin-top: 24px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 56px;
        border-radius: 88px;
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        transition: background-color .2s ease;


        &:first-child {
            background-color: #CE1A30;
            width: 113px;

            &:hover {
                background-color: #8243F0;
            }
        }

        &:last-child {
            width: 100px;
            background-color: transparent;
            border: 2px solid #FFFFFF;

            &:hover {
                background-color: #8243F0;
                border: solid 2px #8243F0;
            }
        }
    }
}

.listDescriptionProgram {
    display: grid;
    margin-top: 8px;
    gap: 8px;

    &__item {
        position: relative;
        font-family: 'FuturaPT';
        padding-left: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #E0E0E0;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 6px;
            height: 6px;
            margin-top: 8px;
            background-color: #CE1A30;
            border-radius: 50%;
            margin-right: 6px;
        }

        .email {
            border-bottom: solid 1px;
        }

        a {
            color: #E0E0E0;
        }
    }
}