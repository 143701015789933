.containerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.preloaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}