.formUserLogin {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    &__title {
        margin-top: 17px;
        margin-bottom: 40px;
        font-family: 'FuturaPT';
        font-weight: 700;
        font-size: 46px;
        line-height: 49px;
        color: #F0F0F0;

        @media (max-width: 415px) {
            font-size: 40px;
            line-height: 43px;
        }

        @media (max-width: 370px) {
            font-size: 32px;
            line-height: 35px;
        }
    }

    &__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        padding: 18px 32px;
        height: 56px;
        background-color: #CE1A30;
        color: #fff;
        border-radius: 88px;
        transition: background-color .2s ease;
        cursor: pointer;

        &:hover {
            background-color: #8243F0;
        }
    }

    &__inputWrapper {
        margin-top: 20px;
        position: relative;
    }

    &__toggleTypeInput {
        position: absolute;
        right: 23px;
        top: calc(50% - 8.5px);
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        cursor: pointer;
    }

    &__messageErrorEnterRequiredFields {
        position: absolute;
        left: calc(50% - 67.5px);
        bottom: 0;
        display: inline-block;
        height: 31px;
        padding: 9px 20px;
        background-color: #C40F24;
        border-radius: 12px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
    }

    &__signature {
        max-width: 271px;
        margin: 0 auto;
        margin-top: 124px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8B8B9E;

        a {
            color: #8B8B9E;
        }
    }

    &__link {
        border-bottom: solid 1px;
    }

    .alreadyAccount {
        margin-top: 37px;
    }
}