.tabsYourFurniActivityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    height: 52px;
    background-color: #262830;
    box-shadow: inset 0 -1px 0px rgba(255, 255, 255, 0.04);
    font-family: 'FuturaPT';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #E0E0E0;
    transition: background-color .2s ease;
    cursor: pointer;

    &__title {
        display: flex;
        align-items: center;
    }

    @media (max-width: 400px) {
        padding: 6px 10px;
        font-size: 14px;
    }

    &:hover {
        background-color: #323540;
    }

    

    &:last-of-type {
        position: sticky;
        bottom: 0;
        background-color: #323540;
        border-radius: 0 0 12px 12px;


        div:not(:first-child) {
            text-align: right;
        }

        // div:first-child {
        //     flex: 1 1 auto;
        // }

        div:not(:last-child) {
            // margin-right: 33px;
        }
    }

    &__name {
        text-align: left;
        flex: 1 1 auto;
        min-width: 130px;
    }

    &__item {
        text-align: right;

        &:not(:last-child) {
            margin-right: 33px;
        }
    }

    &__showDetailsContact {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        background-color: #444755;
        border-radius: 6px;

        @media(max-width: 400px) {
            margin-right: 8px;
        }

        &::after {
            content: "";
            position: relative;
            top: -2px;
            width: 8px;
            height: 8px;
            border: 2px solid #E0E0E0;
            border-right: none;
            border-bottom: none;
            transform: rotate(-135deg);
            transition: 0.2s;
        }
    }

    &__showDetailsContact.active {
        background-color: #A4A4A9;

        &::after {
            content: '';
            border: 2px solid #262830;
            border-right: none;
            border-bottom: none;
            transform: rotate(45deg);
            top: 2px;
        }
    }
}

.tabsYourFurniActivityItem.header {
    &:first-of-type {
        background-color: #323540;
        border-radius: 12px 12px 0 0;
        font-size: 12px;
        line-height: 15px;
        cursor: default;

        div:not(:last-child) {
            display: flex;
            margin-right: 33px;
        }
    }
}

.tabsYourFurniActivityItem.detalContent {
    background-color: #323540;
    padding-left: 56px;

    &:first-of-type,
    &:last-of-type {
        border-radius: 0;
        font-size: 16px;
        line-height: 20px;
    }
}

.tabsYourFurniActivityItem.active {
    background-color: #323540;
}

.tabsYourFurniActivityItem.events {
    &:last-of-type {
        position: static;
        background-color: #262830;
    }
}

.tabsYourFurniActivityItem.events.active {
    &:last-of-type {
        border-radius: 0;
        background-color: #323540;
    }
}

.new_lead {
    color: #E7E960;
}

.customers_communication {
    color: #888888;
}

.customers_agreement_for_a_meeting {
    color: #ef9618;
}

.customers_the_brief_is_filled_in {
    color: #888888;
}

.customers_meeting_with_the_designer {
    color: #888888;
}

.customers_sent_offer {
    color: #ff3ae8;
}

.customers_invoice_sent {
    color: #ff3ae8;
}

.customers_closed_won {
    color: #B3BAFF;
}

.re_calc {
    color: #ff3ae8;
}

.realization_keys_received {
    color: #888888;
}

.realization_buy_products {
    color: #888888;
}

.realization_waiting_for_delivery {
    color: #888888;
}

.realization_products_delivered {
    color: #888888;
}

.realization_cleaning {
    color: #888888;
}

.realization_closed_won {
    color: #22D3A5;
}

.realization_closed_lost {
    color: #F94C4C;
}

.customers_closed_lost {
    color: #F94C4C;
}