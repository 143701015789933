.container {
    max-width: 1024px;
    height: 100%;
    margin: 0 auto;
    padding: 56px 20px 0;

    &>div {
        // overflow-x: auto;

        // &::-webkit-scrollbar {
        //     width: 0;
        //     height: 0;
        // }
    }
}