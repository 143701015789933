.FilterList {
    padding: 8px 20px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    background-color: #323540;
    border: 2px solid #444755;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.64);
    border-radius: 16px;
}

.FilterList.hide {
    z-index: -5;
    opacity: 0;
}